<template>
  <b-container>
    <b-row v-if="!loading">
      <b-col>
        <b-card>
          <b-container>
            <b-row>
              <b-col>
          <p><b-link :to="{ name: 'Alerts' }">back to alerts</b-link></p>
          <h2>
            <b-button class="mr-2" v-if="permission" variant="outline-primary" :to="{ name: 'AlertEdit', params: { id: row.id }}">Edit</b-button>
            {{row.name}}
          </h2>
          <div><markdown :content="row.comments" /></div>
          <p><strong>Detected risks</strong></p>
          <span v-for="risk in risks" :key="risk.id">
            <p><b-link :to="{ name: 'Risk', params: { id: risk.id }}">{{risk.name}}</b-link></p>
          </span>
              </b-col>
            </b-row>

          </b-container>
        </b-card>
      </b-col>
    </b-row>

  </b-container>
</template>

<script>
import _ from 'lodash'
import ac from '../libs/accesscontrol'
import Markdown from '@/components/Markdown.vue'

export default {
  name: 'Alert',
  beforeDestroy () {
  },
  components: {
    Markdown
  },
  computed: {
    user: {
      get () {
        return this.$store.state.user
      }
    }
  },
  created: function () {
    this.$stat.log({ page: 'alert', action: 'open alert', model: 'alert', model_id: this.$route.params.id })
    this.permission = ac.can(this.user.acgroups).createAny('extsource').granted
    this.loadRows()
  },
  data () {
    return {
      loading: true,
      permission: false,
      risks: '',
      row: ''
    }
  },
  methods: {
    loadRows: async function () {
      this.$logger.debug('loadRisks started')
      this.loading = true
      try {
        let apiName = 'cosmos'
        let path = `/alert/${this.$route.params.id}`
        let response = await this.$Amplify.API.get(apiName, path)
        this.row = response
        document.title = "MAR Alert: " + this.row.name
        this.loading = false
      } catch (e) {
        this.$logger.warn('/regbite/:id error ', e)
      }
      // get risk
      try {
        let apiName = 'cosmos'
        let path = `/vertex/${this.$route.params.id}/events/edge/out/detects/vertices`
        let response2 = await this.$Amplify.API.get(apiName, path)
        this.$logger.debug('loadEdges OK', response2)
        this.risks = _.sortBy(response2, ['name'])
      } catch (e) {
        this.$logger.warn('error getting extsources', e)
      }
    }
  }
}
</script>

<style>
</style>
